import React, { useCallback } from "react";
import { Link } from "react-router-dom/dist";
import { AuthApi } from "../../api";
import useSWR from "swr";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
const PaymentSideBar = ({
  sideHeaderToggle,
  setSideHeaderToggle,
  qpay,
  loading,
}) => {
  const { data: user } = useSWR("swr.user.me");

  const checkPayment = useCallback(async () => {
    try {
      const res = await AuthApi.checkPayment(qpay?.invoiceId, user?._id);
      if (res.success) {
        window.location.reload();
        toast("Төлбөр төлөгдсөн");
      }
    } catch (err) {
      toast("Төлбөр төлөгдөөгүй", {
        position: "top-right",
        autoClose: 5000,
        type: "warning",
      });
    }
  }, [qpay?.invoiceId, user?._id]);

  const onClose = () => {
    setSideHeaderToggle(!sideHeaderToggle);
  };

  return (
    <>
      <div
        className={
          sideHeaderToggle
            ? "header__area-box-sidebar-popup active"
            : "header__area-box-sidebar-popup"
        }
      >
        <div className="sidebar-close-btn one" onClick={onClose}>
          {" "}
          <i className="fal fa-times"></i>{" "}
        </div>
        <div className="header__area-box-sidebar-popup-logo">
          <Link to="/">
            <img src="assets/img/logo-1.png" alt="" />
          </Link>
        </div>
        <p>"Ярих чадвараа хөгжүүлье" сургалт нээлттэй!</p>
        {loading ? (
          <div className="header__area-box-sidebar-popup-contact">
            <Spinner animation="grow" />
          </div>
        ) : (
          <div className="header__area-box-sidebar-popup-contact">
            <img
              alt="Base64"
              src={`data:image/png;base64,${qpay?.qrImage}`}
              width={400}
              height={400}
            />
            <button className="theme-btn mt-3" onClick={checkPayment}>
              Шалгах
            </button>
            <div className="row mt-5">
              {qpay?.urls?.map((url) => {
                return (
                  <a href={url.link} className="row col-xl-6 mt-3">
                    <img src={url.logo} width={40} height={40} alt={url.logo} />
                    <p>{url.name}</p>
                  </a>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <div
        className={
          sideHeaderToggle ? "sidebar-overlay show" : "sidebar-overlay"
        }
      ></div>
    </>
  );
};

export default PaymentSideBar;
