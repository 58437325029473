import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthSideBar from "./AuthSideBar";
import useSWR from "swr";
import { AuthApi } from "../../api";
import PaymentSideBar from "./PaymentSideBar";
import QpayAskModal from "../../components/QpayAskModal";
import VoucherSideBar from "./VoucherSideBar";

export default function Header({ payload }) {
  const { data } = useSWR("swr.user.me", { fallbackData: payload });
  const [isSticky, setIsSticky] = useState(false);
  const [isSignUp, setIsSignUp] = useState(true);
  const [sideHeaderToggle, setSideHeaderToggle] = useState(false);
  const [sidePaymentToggle, setSidePaymentToggle] = useState(false);
  const [sideVoucherToggle, setSideVoucherToggle] = useState(false);
  const [mobileToggle, setMobileToggle] = useState(false);
  const [qpay, setQpay] = useState();
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const handlePay = () => {
    setShow(false);
    setSidePaymentToggle(!sidePaymentToggle);
  };
  const onVoucher = async () => {
    setShow(false);
    setSideVoucherToggle(true);
  };
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    });
  }, []);

  const logout = async () => {
    try {
      await AuthApi.logout();
      localStorage.removeItem("boosterUser");
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  const getInvoicne = async () => {
    handleShow();
    try {
      const res = await AuthApi.getInvoince({ userId: data?._id });
      setQpay({
        qrImage: res.data.qrImage,
        invoiceId: res.data.invoiceId,
        urls: res.data.urls,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div
        className={`header__area header__sticky ${
          isSticky ? "header__sticky-active" : ""
        }`}
      >
        <div className="container custom-container">
          <div className="header__area-box">
            <div className="header__area-box-logo">
              <Link to="/">
                <img src="assets/img/logo-1.png" alt="" />
              </Link>
            </div>
            <div className="header__area-box-main-menu one">
              <ul
                className="mobile__menu"
                style={{ display: `${mobileToggle ? "block" : "none"}` }}
              >
                <li>
                  <Link to="/">Нүүр</Link>
                </li>
                {data?.isPayment && (
                  <li>
                    <Link to="/lesson">Сургалт</Link>
                  </li>
                )}
                {!data?.isPayment && data && (
                  <li onClick={getInvoicne}>
                    <Link to="#">Сургалт</Link>
                  </li>
                )}
                <li>
                  <Link to="/about">Миний тухай</Link>
                </li>

                {!data && (
                  <>
                    <li
                      onClick={() => {
                        setSideHeaderToggle(!sideHeaderToggle);
                        setIsSignUp(false);
                      }}
                    >
                      <Link to="#">Нэвтрэх</Link>
                    </li>
                    <li
                      onClick={() => {
                        setSideHeaderToggle(!sideHeaderToggle);
                        setIsSignUp(true);
                      }}
                    >
                      <Link to="#">Бүртгүүлэх</Link>
                    </li>
                  </>
                )}
                <li>
                  <Link to="/contact">Холбоо барих</Link>
                </li>
                {data && (
                  <li onClick={logout}>
                    <Link to="#">Гарах</Link>
                  </li>
                )}
              </ul>
              <span
                className={
                  mobileToggle
                    ? "mobile-menu mobile-menu-active"
                    : "mobile-menu"
                }
                onClick={() => setMobileToggle(!mobileToggle)}
              >
                <span></span>
              </span>
            </div>
            {!data && (
              <div className="header__area-box-sidebar">
                <div className="header__area-box-sidebar-popup-icon">
                  <span onClick={() => setSideHeaderToggle(!sideHeaderToggle)}>
                    <i className="fal fa-bars"></i>
                  </span>
                </div>
              </div>
            )}
            <AuthSideBar
              isSignUp={isSignUp}
              sideHeaderToggle={sideHeaderToggle}
              setIsSignUp={setIsSignUp}
              setSideHeaderToggle={setSideHeaderToggle}
            />
            {qpay && (
              <PaymentSideBar
                setSideHeaderToggle={setSidePaymentToggle}
                sideHeaderToggle={sidePaymentToggle}
                qpay={qpay}
              />
            )}
            <VoucherSideBar
              setSideHeaderToggle={setSideVoucherToggle}
              sideHeaderToggle={sideVoucherToggle}
            />
          </div>
        </div>
      </div>
      <QpayAskModal
        handleClose={handleClose}
        handlePay={handlePay}
        show={show}
        onVoucher={onVoucher}
      />
    </>
  );
}
