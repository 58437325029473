import React from "react";
import { Link } from "react-router-dom";
import { pageTitle } from "../PageTitle";

const SecondServicePage = () => {
  pageTitle("Services");
  const servicesData = [
    {
      id: 1,
      title: "Terminal 1.0",
      description: "Co-Founder & Project leader",
    },
    {
      id: 2,
      title: "Emuna",
      description: "Founder & Chairman",
    },
    {
      id: 3,
      title: "ihelp.mn",
      description: "Founder & CEO",
    },
    {
      id: 4,
      title: "CAREER developer magazine",
      description: "Founder & Editor in Chief",
    },
    {
      id: 5,
      title: "Erdenet City council in UB",
      description: "CEO",
    },
  ];
  return (
    <>
      <div className="services__page section-padding1">
        <div className="container">
          <div className="row mb-30">
            <div className="col-xl-6 col-lg-8">
              <div className="services__page-title lg-t-center">
                <h2>Now</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {servicesData.map((item, id) => (
              <div className="col-xl-12" key={id}>
                <div className="services__page-item">
                  <div className="services__page-item-left">
                    <h4>
                      <Link to={item.href}>{item.title}</Link>
                    </h4>
                  </div>
                  <div className="services__page-item-center">
                    <p>{item.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SecondServicePage;
