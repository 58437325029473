import React from "react";
import HeaderTwo from "../Header/HeaderOne";
import CategoryDetail from "./CategoryDetail";

const CategoryScreen = () => {
  return (
    <>
      <HeaderTwo></HeaderTwo>
      <CategoryDetail></CategoryDetail>
    </>
  );
};

export default CategoryScreen;
