import React from "react";

const VoucherForm = ({ onLogin, register, errors }) => {
  return (
    <div className="header__area-box-sidebar-popup-contact">
      <h4 className="mb-25">Voucher</h4>
      <form onSubmit={onLogin}>
        <div className="row">
          <div className="col-sm-12 mb-30">
            <div className="header__area-box-sidebar-popup-contact-form-item">
              {" "}
              <i className="far fa-gift"></i>
              <input
                type="text"
                name="voucher"
                placeholder="Voucher"
                {...register("voucher", { required: true })}
                className={errors.voucher ? "border-danger" : ""}
              />
              {errors.voucher && (
                <span className="text-danger">{errors.voucher.message}</span>
              )}
            </div>
          </div>
          <div className="col-sm-12">
            <div className="header__area-box-sidebar-popup-contact-form-item">
              <button className="theme-btn" type="submit">
                Оруулах
                <i className="fal fa-long-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default VoucherForm;
