import React from "react";
import { Link } from "react-router-dom";
import useSWR from "swr";
import { LessonApi } from "../../api";

const Blog = () => {
  const { data } = useSWR("swr.lessons.categories", async () => {
    const { data } = await LessonApi.getCategories();
    return data;
  });

  const time = (duration) => {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = duration % 60;

    const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  return (
    <div className="blog__area section-padding">
      <div className="container">
        <div className="row mb-30">
          <div className="col-xl-12">
            <div className="blog__area-title">
              <h2>Сургалт</h2>
            </div>
          </div>
        </div>
        <div className="row">
          {data?.map((item) => (
            <div className="col-xl-4 col-lg-4 col-md-6 mt-30" key={item._id}>
              <div className="blog__area-item">
                <div className="blog__area-item-image" style={{ height: 200 }}>
                  <Link to={`/category-detail/${item._id}`}>
                    <img
                      src={item?.image?.url}
                      alt={item?.image?.url}
                      height={200}
                    />
                  </Link>
                </div>
                <div className="blog__area-item-content">
                  <div className="blog__area-item-content-meta">
                    <ul>
                      <li>
                        <Link to="#">
                          <i className="fal fa-video"></i>
                          {item.lessonsCount}
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fal fa-eye"></i>
                          {time(item.lessonsDuration)}
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <h4>
                    <Link to={`/category-detail/${item._id}`}>{item.name}</Link>
                  </h4>
                  <Link
                    className="simple-btn"
                    to={`/category-detail/${item._id}`}
                  >
                    Үзэх<i className="far fa-long-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blog;
