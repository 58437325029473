import React from "react";
import { Link, useParams } from "react-router-dom";
import { pageTitle } from "../PageTitle";
import useSWR from "swr";
import { LessonApi } from "../../api";
const CategoryDetail = () => {
  const { id } = useParams();
  pageTitle("Сургалт");

  const { data } = useSWR(`swr.category.${id}`, async () => {
    const { data } = await LessonApi.getCategoryLesson(id);
    return data;
  });

  const time = (duration) => {
    const minutes = Math.floor((duration % 3600) / 60);
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.round(duration % 60);

    return `${formattedMinutes}:${seconds}`;
  };

  return (
    <div className="blog__area section-padding">
      <div className="container">
        <div className="row mb-30">
          <div className="col-xl-12">
            <div className="blog__area-title">
              <h2>Ярих чадвараа хөгжүүлье</h2>
            </div>
          </div>
        </div>
        <div className="row">
          {data?.map((item) => (
            <div className="col-xl-4 col-lg-4 col-md-6 mt-30" key={item._id}>
              <div className="blog__area-item">
                <div className="blog__area-item-image" style={{ height: 200 }}>
                  <Link to="#">
                    <img
                      src={item?.video?.image}
                      alt={item?.video?.image}
                      height={200}
                    />
                  </Link>
                </div>
                <div className="blog__area-item-content">
                  <div className="blog__area-item-content-meta">
                    <ul>
                      <li>
                        <Link to="#">
                          <i className="fal fa-video"></i>
                          {time(item.video?.duration || 0)}
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fal fa-eye"></i>
                          {item.seen}
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <h4>
                    <Link to={item.isActive && `/lesson/${item._id}`}>
                      {item.title}
                    </Link>
                  </h4>
                  <p className="mb-3">
                    <Link to={item.isActive && `/lesson/${item._id}`}>
                      {item.description}
                    </Link>
                  </p>
                  <Link
                    className="simple-btn"
                    to={item.isActive && `/lesson/${item._id}`}
                  >
                    Үзэх<i className="far fa-long-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CategoryDetail;
