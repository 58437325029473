import React from "react";
import { Link } from "react-router-dom";
import { pageTitle } from "../PageTitle";

const ServicesPage = () => {
  pageTitle("Services");
  const servicesData = [
    {
      id: 1,
      title: "Morning View",
      description: "Founder & Host",
    },
    {
      id: 2,
      title: "Forbes Mongolia",
      description: "Deputy CEO",
    },
    {
      id: 3,
      title: "Bloomberg TV Mongolia",
      description: "Deputy Sales Director",
    },
    {
      id: 4,
      title: "Asia Mining Magazine",
      description: "CBO",
    },
  ];
  return (
    <>
      <div className="services__page section-padding1">
        <div className="container">
          <div className="row mb-30">
            <div className="col-xl-6 col-lg-8">
              <div className="services__page-title lg-t-center">
                <h2>Former</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {servicesData.map((item, id) => (
              <div className="col-xl-12" key={id}>
                <div className="services__page-item">
                  <div className="services__page-item-left">
                    <h4>
                      <Link to={item.href}>{item.title}</Link>
                    </h4>
                  </div>
                  <div className="services__page-item-center">
                    <p>{item.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesPage;
