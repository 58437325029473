import { get } from "../helpers/api_helper";

export const getLessons = async () => {
  const res = get("/lessons?sort=-createdAt");
  return res;
};
export const getLesson = async (id) => {
  const res = get(`/lessons/${id}`);
  return res;
};

export const getCategories = async () => {
  const res = get("/category");
  return res;
};
export const getCategoryLesson = async (id) => {
  const res = get(`/lessons/category/${id}/articles`);
  return res;
};
