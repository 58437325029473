import React from "react";
import Accordion from "react-bootstrap/Accordion";

const Work = () => {
  return (
    <div className="work__area section-padding">
      <div className="container">
        <div className="row mb-60">
          <div className="col-xl-12">
            <div className="work__area-title lg-t-center">
              <span className="subtitle one">
                Цор ганц удаагийн онлайн сургалтад хамрагдах:
              </span>
              <h2>ЯРИХ ЧАДВАРАА САЙЖРУУЛАХ</h2>
            </div>
          </div>
        </div>
        <div className="row mb-110">
          <div className="col-xl-4 col-lg-4 col-md-6 pr-110 lg-mb-30">
            <div className="work__area-item">
              {" "}
              <span>01</span>
              <div className="work__area-item-icon">
                {" "}
                <img src="assets/img/icon/work-1.png" alt="" />{" "}
              </div>
              <div className="work__area-item-content">
                <h4>Бүртгэлээ үүсгэх </h4>
                <p>
                  Өөрийн товч мэдээллийг бөглөөд бүртгэлээ үүсгэх, төлбөрөө
                  төлөх /QPay үйлчилгээг ашиглан бүх төрлийн интернет банкаар
                  төлөх боломжтой/.
                </p>
              </div>
              <div className="work__area-item-shape">
                {" "}
                <img src="assets/img/icon/shape-1.png" alt="" />{" "}
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-6 pl-55 pr-55 md-mb-30">
            <div className="work__area-item">
              {" "}
              <span>02</span>
              <div className="work__area-item-icon">
                {" "}
                <img src="assets/img/icon/work-2.png" alt="" />{" "}
              </div>
              <div className="work__area-item-content">
                <h4>Бүртгэлээрээ нэвтрэн орох</h4>
                <p>
                  Хичээлүүдийг 2023.12.12-ний өдрөөс эхлэн үзэх боломжтой болох
                  ба зөвхөн үүнээс өмнө бүртгэл үүсгэсэн хэрэглэгч нарт
                  хамрагдах эрх олгогдоно.
                </p>
              </div>
              <div className="work__area-item-shape">
                {" "}
                <img src="assets/img/icon/shape-2.png" alt="" />{" "}
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-6 pl-110">
            <div className="work__area-item">
              {" "}
              <span>03</span>
              <div className="work__area-item-icon">
                {" "}
                <img src="assets/img/icon/work-3.png" alt="" />{" "}
              </div>
              <div className="work__area-item-content">
                <h4>Сургалтдаа хамтрагдах </h4>
                <p>
                  Хичээл бэлэн болсон өдрөөс хойш бүртгэлээрээ нэвтрэн орж
                  цаашид давтан үзэх боломжтой бөгөөд нэмэлт урамшуулал болон
                  бэлгүүдээс сонгон ашиглах.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="faq">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0" className="faq-item">
                  <Accordion.Header className="faq-item-card-header">
                    <h5>
                      “Ярих чадвараа хөгжүүлье” онлайн сургалтад яагаад
                      хамрагдах хэрэгтэй вэ?
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body className="faq-item-card-header-content">
                    <p>
                      Мэдээж хамрагдах, хамрагдахгүй байх нь хувь хүний сонголт.
                      Гэсэн хэдий ч миний хувьд өөрийн өдийг хүртэл зарим
                      тохиолдолд бусдаас арай илүү сурч мэдэж, ойлгож, хөгжүүлж
                      авсан өөрийгөө илэрхийлэх ур чадвар, илтгэх тайлагнах ур
                      чадвар болоод борлуулалт түүнд хамаарагдах ур чадварууд нь
                      зөвхөн надад биш нийгэм, улс эх орны иргэд, дараагийн
                      үеийн залуус бид багаараа хамтдаа хөгжихөд чухал хувь
                      нэмэртэй юм байна гэж бодсоны эцэст бусадтайгаа хуваалцах
                      нь зөв юм болохыг ойлгож энэ ажлыг өрнүүлж байна.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className="faq-item">
                  <Accordion.Header className="faq-item-card-header">
                    <h5>Яагаад ганцхан удаа бас төлбөртэй байгаа вэ?</h5>
                  </Accordion.Header>
                  <Accordion.Body className="faq-item-card-header-content">
                    <p>
                      Аливаа зүйл үнэ цэнтэй төдийгүй, түүнийг хүртэх хүн нь
                      үүнийгээ ойлгож байх нь хамгийн чухал. Мэдээж хоёрхон аяга
                      кофены үнээр хэрэгтэй нэгэнд нь хамрагдах боломжтой
                      нөхцөлийг бүрдүүлж байгаад тайлбар илүүц биз ээ. Харин нэг
                      удаа ойлголт болоод суурь ухагдахуунуудыг нэгтгээд хүргэж
                      байгаа нь цаг хугацаа хэмнэх төдийгүй ойлгомжтой, энгийн
                      байхаар загварчлахыг хичээв.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className="faq-item">
                  <Accordion.Header className="faq-item-card-header">
                    <h5>
                      Ярих, илтгэх, бусадтай ойлголцох болоод борлуулалтын ур
                      чадварын хамгийн чухал нь?
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body className="faq-item-card-header-content">
                    <p>
                      Анхны төсөөллөөр маань бол энэ хуудсыг зорьж орж ирээд
                      сургалт сонирхож буй нөхөд маань ярих ур чадварын чухлыг
                      зах зухаас нь ойлгосон мэдэрсэн хүмүүс гэдэгт итгэлтэй
                      байна. Бид багийн тоглоомоор хөгжиж яваа нийгмийн эд эс.
                      Хажуудах нэгнээ ойлгох, хажуу нөхдөдөө ойлгогдох,
                      бизнесийн хамтрагчтайгаа үр дүнтэй зөвшилцөх, хэрэгтэй
                      хийж бүтээж байгаа зүйлсээ олонд ойлгомжтой хүргэх зэрэг
                      нь зөвхөн хувь хүний төдийгүй хамтын нийгмийн хамгийн
                      чухал ур чадваруудын нэг билээ.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Work;
