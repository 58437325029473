import React, { useState } from "react";
import { Link } from "react-router-dom";
import AuthSideBar from "../../Header/AuthSideBar";
import { AuthApi } from "../../../api";
import PaymentSideBar from "../../Header/PaymentSideBar";
import { Spinner } from "react-bootstrap";
import QpayAskModal from "../../../components/QpayAskModal";
import { toast } from "react-toastify";
import VoucherSideBar from "../../Header/VoucherSideBar";
const Banner = ({ data }) => {
  const [isSignUp, setIsSignUp] = useState(true);
  const [sideHeaderToggle, setSideHeaderToggle] = useState(false);
  const [sidePaymentToggle, setSidePaymentToggle] = useState(false);
  const [sideVoucherToggle, setSideVoucherToggle] = useState(false);
  const [qpay, setQpay] = useState();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const onVoucher = async () => {
    setShow(false);
    setSideVoucherToggle(true);
  };

  const handlePay = async () => {
    try {
      setLoading(true);
      const res = await AuthApi.getInvoince({ userId: data?._id });
      setQpay({
        qrImage: res.data.qrImage,
        invoiceId: res.data.invoiceId,
        urls: res.data.urls,
      });
      setSidePaymentToggle(!sidePaymentToggle);
      setLoading(false);
      setShow(false);
      setSidePaymentToggle(!sidePaymentToggle);
    } catch (err) {
      toast("Төлбөр төлөгдөөгүй", {
        position: "top-right",
        autoClose: 5000,
        type: "warning",
      });
      setLoading(false);
    }
  };
  return (
    <div
      className="banner__area"
      style={{ backgroundImage: `url('assets/img/banner.png')` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="banner__area-content">
              <h1>
                ЯРИХ ЧАДВАРАА <span>ХӨГЖҮҮЛЬЕ {"    "} </span>
              </h1>
              {!data ? (
                <Link
                  className="theme-btn"
                  onClick={() => {
                    setSideHeaderToggle(!sideHeaderToggle);
                    setIsSignUp(true);
                  }}
                  to="#"
                >
                  Бүртгүүлэх <i className="fal fa-long-arrow-right"></i>
                </Link>
              ) : data.isPayment ? (
                <>
                  <Link className="theme-btn" to="/lesson">
                    Сургалт <i className="fal fa-long-arrow-right"></i>
                    {loading && <Spinner animation="grow" />}
                  </Link>
                </>
              ) : (
                <Link className="theme-btn" onClick={handleShow} to="#">
                  Сургалт <i className="fal fa-long-arrow-right"></i>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="banner__area-bottom">
        <div className="banner__area-bottom-icon">
          <ul>
            <li>
              <a
                href="https://www.facebook.com/boosters.mn"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook-f"></i>
              </a>{" "}
            </li>
            <li>
              <a
                href="https://www.instagram.com/boosters.mn"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-instagram"></i>
              </a>{" "}
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCuzP2VNVMLxmudpfwlGoujg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-youtube"></i>
              </a>{" "}
            </li>
          </ul>
        </div>
      </div>
      <AuthSideBar
        isSignUp={isSignUp}
        sideHeaderToggle={sideHeaderToggle}
        setIsSignUp={setIsSignUp}
        setSideHeaderToggle={setSideHeaderToggle}
      />
      {qpay && (
        <PaymentSideBar
          setSideHeaderToggle={setSidePaymentToggle}
          sideHeaderToggle={sidePaymentToggle}
          qpay={qpay}
          loading={loading}
        />
      )}
      <VoucherSideBar
        setSideHeaderToggle={setSideVoucherToggle}
        sideHeaderToggle={sideVoucherToggle}
      />
      <QpayAskModal
        handleClose={handleClose}
        handlePay={handlePay}
        show={show}
        onVoucher={onVoucher}
      />
    </div>
  );
};

export default Banner;
