import React from "react";
import HeaderTwo from "../Header/HeaderOne";
import Blog from "./Blog";

const LessonScreen = () => {
  return (
    <>
      <HeaderTwo></HeaderTwo>
      <Blog></Blog>
    </>
  );
};

export default LessonScreen;
