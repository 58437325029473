import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom/dist";
import SignUpForm from "../auth/SignUpForm";
import LoginForm from "../auth/LoginForm";
import { AuthApi } from "../../api";
import axios from "axios";
import { toast } from "react-toastify";
import QpayAskModal from "../../components/QpayAskModal";
import VoucherSideBar from "./VoucherSideBar";

const AuthSideBar = ({
  sideHeaderToggle,
  setSideHeaderToggle,
  setIsSignUp,
  isSignUp,
}) => {
  const [isPayment, setIsPayment] = useState(false);
  const [sideVoucherToggle, setSideVoucherToggle] = useState(false);
  const [user, setUser] = useState();
  const [qpay, setQpay] = useState();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [token, setToken] = useState("");
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const onVoucher = async () => {
    setShow(false);
    setSideVoucherToggle(true);
  };
  const onPayment = async () => {
    axios
      .post(
        `https://boostersback.com/users/invoice/${user?._id}`,
        {
          amount: 50000,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setQpay({
          qrImage: res.data.data.qrImage,
          invoiceId: res.data.data.invoiceId,
          urls: res.data.data.urls,
        });
        setLoading(false);
        setShow(false);
        setSideHeaderToggle(!sideHeaderToggle);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const onLogin = async (data) => {
    try {
      const res = await AuthApi.login(data);
      localStorage.setItem("boosterUser", JSON.stringify(res.token));
      setUser(res?.user);
      setIsPayment(res?.user?.isPayment);
      setToken(res?.token);
      if (res?.user?.isPayment) {
        window.location.reload();
      } else {
        handleShow();
        setSideHeaderToggle(!sideHeaderToggle);
      }
    } catch (err) {
      setError(err?.response.status || "");
    }
  };
  const onSignUp = async (data) => {
    try {
      setLoading(true);
      const res = await AuthApi.register(data);
      localStorage.setItem("boosterUser", JSON.stringify(res.token));
      setUser(res?.user);
      setIsPayment(res?.user?.isPayment);
      setToken(res?.token);
      // if (res?.user?.isPayment) {
      //   window.location.reload();
      // } else {
      //   handleShow();
      //   setSideHeaderToggle(!sideHeaderToggle);
      // }
      window.location.reload();
    } catch (err) {
      setError(true);
    }
  };

  const checkPayment = useCallback(async () => {
    try {
      const res = await AuthApi.checkPayment(qpay?.invoiceId, user?._id);
      if (res.success) {
        window.location.reload();
        toast("Төлбөр төлөгдсөн");
      }
    } catch (err) {
      toast("Төлбөр төлөгдөөгүй", {
        position: "top-right",
        autoClose: 5000,
        type: "warning",
      });
    }
  }, [qpay?.invoiceId, user?._id]);

  const onClose = () => {
    if (user) {
      window.location.reload();
    }
    setSideHeaderToggle(!sideHeaderToggle);
    setIsSignUp(true);
    setShow(false);
  };
  return (
    <>
      <div
        className={
          sideHeaderToggle
            ? "header__area-box-sidebar-popup active"
            : "header__area-box-sidebar-popup"
        }
      >
        <div className="sidebar-close-btn one" onClick={onClose}>
          {" "}
          <i className="fal fa-times"></i>{" "}
        </div>
        <div className="header__area-box-sidebar-popup-logo">
          <Link to="/">
            <img src="assets/img/logo-1.png" alt="" />
          </Link>
        </div>
        <p>"Ярих чадвараа хөгжүүлье" сургалт нээлттэй!</p>
        {/* sign up */}
        {isSignUp ? (
          <SignUpForm
            onSignUp={onSignUp}
            user={user}
            isPayment={isPayment}
            error={error}
            setError={setError}
            qpay={qpay}
            checkPayment={checkPayment}
            loading={loading}
          />
        ) : (
          <LoginForm
            onLogin={onLogin}
            user={user}
            isPayment={isPayment}
            error={error}
            setError={setError}
            qpay={qpay}
            checkPayment={checkPayment}
            loading={loading}
          />
        )}
      </div>
      <div
        className={
          sideHeaderToggle ? "sidebar-overlay show" : "sidebar-overlay"
        }
      ></div>
      <VoucherSideBar
        setSideHeaderToggle={setSideVoucherToggle}
        sideHeaderToggle={sideVoucherToggle}
      />
      <QpayAskModal
        show={show}
        handleClose={handleClose}
        handlePay={onPayment}
        onVoucher={onVoucher}
      />
    </>
  );
};

export default AuthSideBar;
