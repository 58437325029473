import React, { useState } from "react";
import ScrollTrigger from "react-scroll-trigger";
import Features from "./Features";
import CountUp from "react-countup";
import FooterThree from "../Footer/FooterThree";
import Header from "../Header/HeaderOne";
import { pageTitle } from "../PageTitle";
import Services from "./Services";
import SecondServicePage from "./Services1";

const AboutPage = () => {
  pageTitle("About Us");
  const [skillBar, setSkillBar] = useState(false);
  return (
    <>
      <Header></Header>
      <ScrollTrigger
        onEnter={() => setSkillBar(true)}
        onExit={() => setSkillBar(false)}
      >
        <div className="about__page section-padding pb-50 ">
          <div className="container">
            <div className="row mb-60">
              <div className="col-xl-6 col-lg-8">
                <div className="about__page-title">
                  <span className="subtitle">About me</span>
                  <h2>Namkhaidorj Ganbat</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="about__page-area">
                  <div className="about__page-area-experience">
                    <h3>
                      <span className="counter">
                        {skillBar && (
                          <CountUp
                            start={0}
                            end={10}
                            duration={1}
                            delay={0}
                          ></CountUp>
                        )}
                        +
                      </span>
                    </h3>
                    <p>Years Experience In Business industry</p>
                  </div>
                  <img src="assets/img/naki.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ScrollTrigger>
      <Features></Features>
      <SecondServicePage />
      <Services />

      <FooterThree></FooterThree>
    </>
  );
};

export default AboutPage;
