import React from "react";
import { Button, Modal } from "react-bootstrap";
const QpayAskModal = ({ show, handleClose, handlePay, onVoucher }) => {
  const onClose = () => {
    handleClose();
    window.location.reload();
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <div className="blog__details pl-20 pr-20 pb-30 pt-30">
        <Modal.Header>
          <h3>Төлбөр баталгаажуулах</h3>
        </Modal.Header>
        <div className="container mb-10">
          <div className="col-xl-12 col-lg-12 lg-mb-30">
            <div className="blog__details-left">
              <h3 className="mb-3 mt-2">50,000 ₮</h3>
              <p>"Ярих чадвараа хөгжүүлье" сургалт нээлттэй!</p>
              <p className="mt-4">
                Ярих, өөрийгөө илэрхийлэх ур чадвар болон илтгэл тавих, ажлаа
                тайлагнах, борлуулалтын ур чадварууд зэрэгтэй холбоотой -
                сэтгэлгээний өөрчлөлт авчрах үндсэн зорилготой цэвэр практик
                дээр тулгуурласан 7 цуврал багц видео хичээл.
              </p>

              <p className="mt-1">
                Энэ чиглэлийн хичээлийг цор ганц удаа лекц хэлбэрээр хүргэж буй
                бөгөөд зөвхөн бүртгэлтэй хэрэглэгч нарт онцгойлон зориулав.
              </p>
              <br />
              <p>
                <i>Хуулбарлах хориотой, зохиогчийн эрхтэй.</i>
              </p>
            </div>
          </div>
        </div>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Хаах
          </Button>
          <Button variant="info" onClick={onVoucher}>
            Voucher
          </Button>
          <Button variant="primary" onClick={handlePay}>
            Авах
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default QpayAskModal;
