import React from "react";
import HeaderOne from "../../Header/HeaderOne";
import Banner from "../Banner/Banner";
import Features from "../Features/Features";
import Services from "../Services/Services";
import Video from "../Video/Video";
import bannerBg from "../../../assets/img/video.png";
import Work from "../Work/Work";
import { pageTitle } from "../../PageTitle";
import FooterThree from "../../Footer/FooterThree";
import useSWR from "swr";
import Banner1 from "../../Lesson/Banner";
const HomeOne = () => {
  pageTitle("Нүүр");
  const { data } = useSWR("swr.user.me");
  return (
    <>
      <HeaderOne payload={data}></HeaderOne>
      {data ? <Banner1 isHome></Banner1> : <Banner></Banner>}
      <Features></Features>
      <Services></Services>
      <Video bannerBg={bannerBg}></Video>
      <Work></Work>
      <FooterThree data={data}></FooterThree>
    </>
  );
};

export default HomeOne;
