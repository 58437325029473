import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom/dist";
import { Modal } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
const SignUpForm = ({
  user,
  isPayment,
  onSignUp,
  error,
  setError,
  qpay,
  checkPayment,
  loading,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  if (!!user && !isPayment) {
    if (loading) {
      return (
        <div className="header__area-box-sidebar-popup-contact">
          <Spinner animation="grow" />
        </div>
      );
    }
    return (
      <div className="header__area-box-sidebar-popup-contact">
        <img
          alt="Base64"
          src={`data:image/png;base64,${qpay?.qrImage}`}
          width={400}
          height={400}
        />
        <button className="theme-btn mt-3" onClick={checkPayment}>
          Шалгах
        </button>
        <div className="row mt-5">
          {qpay?.urls?.map((url) => {
            return (
              <a href={url.link} className="row col-6 mt-3" key={url.logo}>
                <img src={url.logo} width={40} height={40} alt={url.logo} />
                <p>{url.name}</p>
              </a>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div className="header__area-box-sidebar-popup-contact">
      <h4 className="mb-25">Бүртгүүлэх</h4>
      <form onSubmit={handleSubmit(onSignUp)}>
        <div className="row">
          <div className="col-sm-12 mb-30">
            <div className="header__area-box-sidebar-popup-contact-form-item">
              {" "}
              <i className="far fa-phone"></i>
              <input
                type="text"
                name="phone"
                placeholder="Утасны дугаар"
                {...register("phone", { required: true })}
                className={errors.phone || error ? "border-danger" : ""}
              />
              {errors.phone && (
                <span className="text-danger">Заавал оруулна уу</span>
              )}
              {error && (
                <span className="text-danger">Бүртгэлтэй хэрэглэгч</span>
              )}
            </div>
          </div>
          <div className="col-sm-12 mb-30">
            <div className="header__area-box-sidebar-popup-contact-form-item">
              <i className="far fa-envelope"></i>
              <input
                type="email"
                name="email"
                placeholder="И-мэйл хаяг"
                {...register("email", { required: true })}
                className={errors.email ? "border-danger" : ""}
              />
              {errors.email && (
                <span className="text-danger ">Заавал оруулна уу</span>
              )}
            </div>
          </div>
          <div className="col-sm-12 mb-30">
            <div className="header__area-box-sidebar-popup-contact-form-item">
              {" "}
              <i className="far fa-user"></i>
              <input
                type="text"
                name="name"
                placeholder="Нэр"
                {...register("name", { required: true })}
                className={errors.name ? "border-danger" : ""}
              />
              {errors.name && (
                <span className="text-danger ">Заавал оруулна уу</span>
              )}
            </div>
          </div>
          <div className="col-sm-12 mb-30">
            <div className="header__area-box-sidebar-popup-contact-form-item">
              {" "}
              <i className="far fa-lock"></i>
              <input
                type="password"
                name="password"
                placeholder="Нууц үг"
                {...register("password", { required: true })}
                className={errors.password ? "border-danger" : ""}
              />
              {errors.password && (
                <span className="text-danger">Заавал оруулна уу</span>
              )}
            </div>
          </div>
          <div className="col-sm-12 mb-30">
            <div className="header__area-box-sidebar-popup-contact-form-item">
              <i className="far fa-lock"></i>
              <input
                type="password"
                name="password_repeat"
                placeholder="Нууц үг"
                {...register("password_repeat", {
                  required: "Please confirm your password",
                  validate: (value) =>
                    value === getValues("password") || "Passwords do not match",
                })}
                className={errors.password_repeat ? "border-danger" : ""}
              />
              {errors.password_repeat && (
                <span className="text-danger">Нууц үг тохирсонгүй</span>
              )}
            </div>
          </div>
          <div className="col-sm-12 mb-5">
            <label>
              <input
                type="checkbox"
                name="agree"
                {...register("agree", {
                  required: "You must agree to the terms",
                })}
              />{" "}
              Үйлчилгээний нөхцөл{" "}
              <Link to={"#"} onClick={handleShow}>
                <strong className="text-primary">зөвшөөрөх</strong>
              </Link>
            </label>
            {errors.agree && (
              <span className="text-danger">
                Үйлчилгээний нөхцөл зөвшөөрнө үү
              </span>
            )}
          </div>
          <div className="col-sm-12">
            <div className="header__area-box-sidebar-popup-contact-form-item">
              <button
                className="theme-btn"
                type="submit"
                onClick={() => setError(false)}
              >
                Бүртгүүлэх
                <i className="fal fa-long-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <div className="blog__details pt-30 pl-20 pr-20 pb-30">
          <div className="container">
            <div className="col-xl-12 col-lg-12 lg-mb-30">
              <div className="blog__details-left">
                <h3 className="mb-20">Товч үйлчилгээний нөхцөл</h3>
                <h5>1. Ерөнхий</h5>
                <li className="ml-50 mt-3">
                  1. Boosters.mn-р дамжуулан сургалт олгох, авах, түүнийг
                  ашиглах, хэрэглэхтэй холбоотой үүсэх харилцааг зохицуулахад
                  оршино.
                </li>
                <li className="ml-50 mt-3">
                  2. Энэхүү үйлчилгээний товч нөхцлийг дээрх үйлчилгээг авахаас
                  өмнө хэрэглэгч хүлээн зөвшөөрч баталгаажуулсны үндсэн дээр
                  хэрэгжинэ.
                </li>
                <li className="ml-50 mt-3">
                  3. Boosters.mn болон энэхүү сургалтын үйл ажиллагаатай
                  холбоотой мэдээ мэдээлэл, контент, нийтлэл, хичээлийг
                  зөвшөөрөлгүй түгээх, хуулбарлах бүх үйлдлийг хориглоно.
                </li>
                <h5 className="mt-5">2. Бүртгэл</h5>
                <li className="ml-50 mt-3">
                  1. Сургалтад хамрагдахын тулд хэрэглэгч бүртгэл үүсгэсэн
                  байна. Ингэхдээ нэвтрэх “Нэр” болон “Нууц үгийг” өөрт
                  тохируулан үүсгэж үүнийгээ мартахгүй байх үүргийг хүлээнэ.
                </li>
                <li className="ml-50 mt-3">
                  2. Хэрэглэгчийн мэдээллийн нууцлалыг Boosters.mn хамгаална,
                  задруулахгүй.
                </li>
                <h5 className="mt-5">3. Төлбөр</h5>
                <li className="ml-50 mt-3">
                  1. Хэрэглэгч заагдсан үнийн дүнгээр төлбөрөө төлнө.
                </li>
                <li className="ml-50 mt-3">
                  2. Хэрэглэгч төлбөрөө төлөхөөс өмнө шийдвэрээ бүрэн зөв
                  гаргасан байх ба төлсний дараа төлбөр буцаагдахгүй.
                </li>
              </div>
            </div>
          </div>
          <div className="col-sm-12 right mt-3">
            <div className="header__area-box-sidebar-popup-contact-form-item">
              <button
                className="theme-btn"
                type="submit"
                onClick={() => {
                  setValue("agree", true);
                  setShow(false);
                }}
              >
                3өвшөөрөх
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SignUpForm;
