import React from "react";
import { useParams } from "react-router-dom";
import FooterThree from "../Footer/FooterThree";
import Header from "../Header/HeaderOne";
import { pageTitle } from "../PageTitle";
import { LessonApi } from "../../api";
import useSWR from "swr";
import ReactPlayer from "react-player";
const LessonDetail = () => {
  const { id } = useParams();
  pageTitle(id);

  const { data } = useSWR(`swr.lesson.${id}`, async () => {
    const { data } = await LessonApi.getLesson(id);
    return data;
  });

  if (!data) {
    return null;
  }
  return (
    <>
      <Header></Header>
      <div className="blog__details section-padding">
        <div className="container">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <ReactPlayer
              url={data.video?.url}
              controls={true}
              pip
              width="90%"
              light={<img src={data.video?.image} alt="" width={"70%"} />}
              config={{
                file: { attributes: { controlsList: "nodownload" } },
              }}
            />
            <h3 className="mb-20 mt-3">{data.title}</h3>
            <p>{data.description}</p>
          </div>
        </div>
      </div>
      <FooterThree></FooterThree>
    </>
  );
};

export default LessonDetail;
