import React from "react";
import { Link } from "react-router-dom";

const Services = () => {
  const servicesData = [
    {
      id: 1,
      title: "Ярих чадвараа хөгжүүлье",
      batch: "01",
      description:
        "Өөрийн өдийг хүртэл практик дээр сурсан мэдсэн ойлгосон бүхий л зүйлсийг агуулгаар нь #ганцхан удаа бүртгэл үүсгэсэн хүмүүсд хүргэх болно.",
      imgOne: "assets/img/icon/services-1.png",
      imgTwo: "assets/img/icon/services-11.png",
      href: "/services-details",
    },
    {
      id: 2,
      title: "KICKING.",
      batch: "02",
      description:
        "Борлуулалтын ур чадвар, бизнес болон ажлын байран дээр хамгийн чухлаар шаардагдах борлуулагч сэтгэлгээний ур чадварыг тайлбарласан гарын авлага /тун удахгүй/.",
      imgOne: "assets/img/icon/services-2.png",
      imgTwo: "assets/img/icon/services-22.png",
      href: "/services-details",
    },
    {
      id: 3,
      title: "Байгууллагын сургалт",
      batch: "03",
      description:
        "Борлуулалтын арга барилд шаардагдах зохион байгуулалтыг оношилж түүнийг сайжруулах арга замыг менежерүүдэд тайлбарлах сургалт.",
      imgOne: "assets/img/icon/services-3.png",
      imgTwo: "assets/img/icon/services-33.png",
      href: "/services-details",
    },
    {
      id: 4,
      title: "Personal coaching",
      batch: "04",
      description:
        "Өөрийгөө хөгжүүлэх хүсэл сонирхолтой цөөн тооны хүмүүсийг тусгайлан дадлагажуулах, сургах",
      imgOne: "assets/img/icon/services-4.png",
      imgTwo: "assets/img/icon/services-44.png",
      href: "/services-details",
    },
  ];
  return (
    <div
      className="services__area section-padding"
      style={{ backgroundImage: `url('assets/img/services.png')` }}
    >
      <div className="container">
        <div className="row mb-30">
          <div className="col-xl-12">
            <div className="services__area-title lg-t-center">
              <span className="subtitle one">
                Сайн ярих, илтгэх, өөрийгөө зөв илэрхийлэх нь төрмөл биш олдмол
                ур чадвар.
              </span>
              <h2>Хамтдаа хөгжүүлье</h2>
            </div>
          </div>
        </div>
        <div className="row">
          {servicesData.map((item, id) => (
            <div className="col-xl-6 col-lg-6 col-md-6" key={id}>
              <div className="services__area-item">
                <span>{item.batch}</span>
                <div className="services__area-item-icon">
                  <img src={item.imgTwo} alt="" />
                  <div className="services__area-item-icon-one">
                    <img src={item.imgOne} alt="" />
                  </div>
                </div>
                <div className="services__area-item-content">
                  <h4>
                    <Link to="/services-details">{item.title}</Link>
                  </h4>
                  <p>{item.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
