import React from "react";
import FooterThree from "../Footer/FooterThree";
import Header from "../Header/HeaderOne";
import { pageTitle } from "../PageTitle";

const Privacy = () => {
  pageTitle("News Details");
  return (
    <>
      <Header></Header>
      <div className="blog__details section-padding">
        <div className="container">
          <div className="col-xl-12 col-lg-12 lg-mb-30">
            <div className="blog__details-left">
              <h3 className="mb-20">Товч үйлчилгээний нөхцөл</h3>
              <h5>1. Ерөнхий</h5>
              <li className="ml-50 mt-3">
                1. Boosters.mn-р дамжуулан сургалт олгох, авах, түүнийг ашиглах,
                хэрэглэхтэй холбоотой үүсэх харилцааг зохицуулахад оршино.
              </li>
              <li className="ml-50 mt-3">
                2. Энэхүү үйлчилгээний товч нөхцлийг дээрх үйлчилгээг авахаас
                өмнө хэрэглэгч хүлээн зөвшөөрч баталгаажуулсны үндсэн дээр
                хэрэгжинэ.
              </li>
              <li className="ml-50 mt-3">
                3. Boosters.mn болон энэхүү сургалтын үйл ажиллагаатай холбоотой
                мэдээ мэдээлэл, контент, нийтлэл, хичээлийг зөвшөөрөлгүй түгээх,
                хуулбарлах бүх үйлдлийг хориглоно.
              </li>
              <h5 className="mt-5">2. Бүртгэл</h5>
              <li className="ml-50 mt-3">
                1. Сургалтад хамрагдахын тулд хэрэглэгч бүртгэл үүсгэсэн байна.
                Ингэхдээ нэвтрэх “Нэр” болон “Нууц үгийг” өөрт тохируулан үүсгэж
                үүнийгээ мартахгүй байх үүргийг хүлээнэ.
              </li>
              <li className="ml-50 mt-3">
                2. Хэрэглэгчийн мэдээллийн нууцлалыг Boosters.mn хамгаална,
                задруулахгүй.
              </li>
              <h5 className="mt-5">3. Төлбөр</h5>
              <li className="ml-50 mt-3">
                1. Хэрэглэгч заагдсан үнийн дүнгээр төлбөрөө төлнө.
              </li>
              <li className="ml-50 mt-3">
                2. Хэрэглэгч төлбөрөө төлөхөөс өмнө шийдвэрээ бүрэн зөв гаргасан
                байх ба төлсний дараа төлбөр буцаагдахгүй.
              </li>
            </div>
          </div>
        </div>
      </div>
      <FooterThree></FooterThree>
    </>
  );
};

export default Privacy;
