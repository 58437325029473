import React from "react";
import { Link } from "react-router-dom/dist";
import VoucherForm from "../auth/VoucherForm";
import { useForm } from "react-hook-form";
import { AuthApi } from "../../api";

const VoucherSideBar = ({ sideHeaderToggle, setSideHeaderToggle }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  const onVoucher = async (data) => {
    const gg = {
      code: data.voucher,
    };
    try {
      await AuthApi.vocuher(gg);
      window.location.reload();
      setSideHeaderToggle(!sideHeaderToggle);
    } catch (err) {
      setError("voucher", {
        message: err.response.data.error.message,
      });
    }
  };

  const onClose = () => {
    setSideHeaderToggle(!sideHeaderToggle);
  };
  return (
    <>
      <div
        className={
          sideHeaderToggle
            ? "header__area-box-sidebar-popup active"
            : "header__area-box-sidebar-popup"
        }
      >
        <div className="sidebar-close-btn one" onClick={onClose}>
          {" "}
          <i className="fal fa-times"></i>{" "}
        </div>
        <div className="header__area-box-sidebar-popup-logo">
          <Link to="/">
            <img src="assets/img/logo-1.png" alt="" />
          </Link>
        </div>
        <p>"Ярих чадвараа хөгжүүлье" сургалт voucher оруулах!</p>
        <VoucherForm
          onLogin={handleSubmit(onVoucher)}
          setError={setError}
          register={register}
          errors={errors}
        />
      </div>
      <div
        className={
          sideHeaderToggle ? "sidebar-overlay show" : "sidebar-overlay"
        }
      ></div>
    </>
  );
};

export default VoucherSideBar;
