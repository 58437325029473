import React, { useState } from "react";
import { Link } from "react-router-dom";
import AuthSideBar from "../Header/AuthSideBar";
import { AuthApi } from "../../api";

const FooterThree = ({ data }) => {
  const [isSignUp, setIsSignUp] = useState(true);
  const [sideHeaderToggle, setSideHeaderToggle] = useState(false);
  const logout = async () => {
    try {
      await AuthApi.logout();
      localStorage.removeItem("boosterUser");
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <div className="footer__area pt-60 pb-60">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12">
              <div className="footer__area-widget t-center">
                <div className="footer__area-widget-about">
                  <div className="footer__area-widget-about-social three">
                    <ul>
                      <li>
                        <a
                          href="https://www.facebook.com/boosters.mn"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fab fa-facebook-f"></i>
                        </a>{" "}
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/boosters.mn"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fab fa-instagram"></i>
                        </a>{" "}
                      </li>
                      <li>
                        <a
                          href="https://www.youtube.com/channel/UCuzP2VNVMLxmudpfwlGoujg"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fab fa-youtube"></i>
                        </a>{" "}
                      </li>
                    </ul>
                  </div>
                  <div className="footer__area-widget-about-menu three">
                    <ul>
                      <li>
                        <Link to="/">Нүүр</Link>
                      </li>
                      {data?.isPayment && (
                        <li>
                          <Link to="/lesson">Сургалт</Link>
                        </li>
                      )}

                      {!data ? (
                        <>
                          <li
                            onClick={() => {
                              setSideHeaderToggle(!sideHeaderToggle);
                              setIsSignUp(false);
                            }}
                          >
                            <Link to="#">Нэвтрэх</Link>
                          </li>
                          <li
                            onClick={() =>
                              setSideHeaderToggle(!sideHeaderToggle)
                            }
                          >
                            <Link to="#">Бүртгүүлэх</Link>
                          </li>
                        </>
                      ) : (
                        <li onClick={logout}>
                          <Link to="#">Гарах</Link>
                        </li>
                      )}
                      <li>
                        <Link to="/contact">Холбоо барих</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright__area">
        <div className="container">
          <div className="row align-items-center copyright__area-border">
            <div className="col-xl-12">
              <div className="copyright__area-left t-center">
                <p>
                  Зохиогчийн эрх © 2023<a href="/contact"> Boosters.mn</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AuthSideBar
        isSignUp={isSignUp}
        sideHeaderToggle={sideHeaderToggle}
        setIsSignUp={setIsSignUp}
        setSideHeaderToggle={setSideHeaderToggle}
      />
    </>
  );
};

export default FooterThree;
