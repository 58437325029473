import React from "react";
import { Link } from "react-router-dom";
import FooterThree from "../Footer/FooterThree";
import Header from "../Header/HeaderOne";
import { pageTitle } from "../PageTitle";
import useSWR from "swr";

const Contact = () => {
  pageTitle("Холбоо барих");
  const { data } = useSWR("swr.user.me");
  return (
    <>
      <Header payload={data}></Header>
      <div className="contact__area section-padding">
        <div className="container">
          <div className="row mb-60">
            <div className="col-xl-5 col-lg-6">
              <div className="contact__area-title">
                <span className="subtitle">Холбоо барих</span>
                <h2>Г. Намхайдорж</h2>
              </div>
              <div className="contact__area-info">
                <div className="contact__area-info-item">
                  <div className="contact__area-info-item-icon">
                    <i className="fal fa-envelope"></i>
                    <span>И-мэйл :</span>
                  </div>
                  <div className="contact__area-info-item-content">
                    <a href="mailto:support@gamil.com">
                      naki.mongolia@gmail.com
                    </a>
                  </div>
                </div>
                <div className="contact__area-info-item">
                  <div className="contact__area-info-item-icon">
                    <i className="fab fa-facebook"></i>
                    <span>Фэйсбүүк :</span>
                  </div>
                  <div className="contact__area-info-item-content">
                    <a
                      href="https://www.facebook.com/namkhaidorj.gb"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Namkhaidorj GB.
                    </a>
                  </div>
                </div>
                <div className="contact__area-info-item">
                  <div className="contact__area-info-item-icon">
                    <i className="fab fa-instagram"></i>
                    <span>Instagram :</span>
                  </div>
                  <div className="contact__area-info-item-content">
                    <a
                      href="https://www.instagram.com/ga_naki/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      ga_naki
                    </a>
                  </div>
                </div>
                <div className="contact__area-info-item">
                  <div className="contact__area-info-item-icon">
                    <i className="fab fa-linkedin"></i>
                    <span>Linkedin :</span>
                  </div>
                  <div className="contact__area-info-item-content">
                    <a
                      href="https://www.linkedin.com/in/ga-naki"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      ga_naki
                    </a>
                  </div>
                </div>
                <div className="contact__area-info-item">
                  <div className="contact__area-info-item-icon">
                    <i className="far fa-map-marker-alt"></i>
                    <span>Байршил :</span>
                  </div>
                  <div className="contact__area-info-item-content">
                    <Link to="#">Монгол улс, Улаанбаатар хот</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterThree></FooterThree>
    </>
  );
};

export default Contact;
