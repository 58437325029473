import React, { useState } from "react";
import { Link } from "react-router-dom";
import useSWR from "swr";
import QpayAskModal from "../../components/QpayAskModal";
import { AuthApi } from "../../api";
import PaymentSideBar from "../Header/PaymentSideBar";
import { toast } from "react-toastify";
import VoucherSideBar from "../Header/VoucherSideBar";
import bannerBg from "../../assets/img/video.png";
const Banner = ({ isHome }) => {
  const { data } = useSWR("swr.user.me");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [qpay, setQpay] = useState();
  const [sidePaymentToggle, setSidePaymentToggle] = useState(false);
  const [sideVoucherToggle, setSideVoucherToggle] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const onVoucher = async () => {
    setShow(false);
    setSideVoucherToggle(true);
  };

  const handlePay = async () => {
    try {
      setLoading(true);
      const res = await AuthApi.getInvoince({ userId: data?._id });
      setQpay({
        qrImage: res.data.qrImage,
        invoiceId: res.data.invoiceId,
        urls: res.data.urls,
      });
      setSidePaymentToggle(!sidePaymentToggle);
      setLoading(false);
      setShow(false);
      setSidePaymentToggle(!sidePaymentToggle);
    } catch (err) {
      toast("Төлбөр төлөгдөөгүй", {
        position: "top-right",
        autoClose: 5000,
        type: "warning",
      });
      setLoading(false);
    }
  };

  return (
    <div
      className="banner__area-two"
      style={{
        backgroundImage: `url(${bannerBg})`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="banner__area-two-content">
              {isHome ? (
                <>
                  {data && !data.isPayment ? (
                    <Link className="theme-btn-1" to="#" onClick={handleShow}>
                      Төлбөр төлөх <i className="fal fa-long-arrow-right"></i>
                    </Link>
                  ) : (
                    <Link className="theme-btn-1" to="/lesson">
                      Үзэх <i className="fal fa-long-arrow-right"></i>
                    </Link>
                  )}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="banner__area-two-bottom">
        <div className="container">
          <div className="banner__area-two-bottom-icon">
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/boosters.mn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>{" "}
              </li>
              <li>
                <a
                  href="https://www.instagram.com/boosters.mn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-instagram"></i>
                </a>{" "}
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCuzP2VNVMLxmudpfwlGoujg"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-youtube"></i>
                </a>{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>
      {qpay && (
        <PaymentSideBar
          setSideHeaderToggle={setSidePaymentToggle}
          sideHeaderToggle={sidePaymentToggle}
          qpay={qpay}
          loading={loading}
        />
      )}
      <VoucherSideBar
        setSideHeaderToggle={setSideVoucherToggle}
        sideHeaderToggle={sideVoucherToggle}
      />
      <QpayAskModal
        handleClose={handleClose}
        handlePay={handlePay}
        show={show}
        onVoucher={onVoucher}
      />
    </div>
  );
};

export default Banner;
