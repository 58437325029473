import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Contact from "./Pages/Contact/Contact";
import Error from "./Pages/Error/Error";
import HomeOne from "./Pages/HomeOne/HomeOne/HomeOne";
import Preloader from "./Pages/Preloader/Preloader";
import ScrollTop from "./Pages/ScrollTop/ScrollTop";
import LessonScreen from "./Pages/Lesson/LessonScreen";
import useSWR, { SWRConfig } from "swr";
import { AuthApi } from "./api";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AboutPage from "./Pages/about/AboutPage";
import Privacy from "./Pages/auth/Privacy";
import CategoryScreen from "./Pages/Lesson/CategoryScreen";
import LessonDetail from "./Pages/Lesson/LessonDetail";
function App() {
  const [loader, setLoader] = useState(true);
  const { data } = useSWR("swr.user.me", async () => {
    const { data } = await AuthApi.me();
    return data;
  });
  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }, []);

  return (
    <SWRConfig>
      {loader && <Preloader />}
      <Router>
        <Routes>
          <Route path="/" element={<HomeOne />} />
          {data?.isPayment && (
            <>
              <Route path="/lesson" element={<LessonScreen />} />
              <Route path="/lesson/:id" element={<LessonDetail />} />
              <Route path="/category-detail/:id" element={<CategoryScreen />} />
            </>
          )}
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </Router>
      <ToastContainer />
      <ScrollTop />
    </SWRConfig>
  );
}

export default App;
