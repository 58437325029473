import React from "react";
import { useForm } from "react-hook-form";
import { Spinner } from "react-bootstrap";
const LoginForm = ({
  user,
  isPayment,
  onLogin,
  error,
  setError,
  qpay,
  checkPayment,
  loading,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  if (!!user && !isPayment) {
    if (loading) {
      return (
        <div className="header__area-box-sidebar-popup-contact">
          <Spinner animation="grow" />
        </div>
      );
    }
    return (
      <div className="header__area-box-sidebar-popup-contact">
        <img
          alt="Base64"
          src={`data:image/png;base64,${qpay?.qrImage}`}
          width={400}
          height={400}
        />
        <button className="theme-btn mt-3" onClick={checkPayment}>
          Шалгах
        </button>
        <div className="row mt-5">
          {qpay?.urls?.map((url) => {
            return (
              <a href={url.link} className="row col-xl-6 mt-3">
                <img src={url.logo} width={40} height={40} alt={url.logo} />
                <p>{url.name}</p>
              </a>
            );
          })}
        </div>
      </div>
    );
  }
  return (
    <div className="header__area-box-sidebar-popup-contact">
      <h4 className="mb-25">Нэвтрэх</h4>
      <form onSubmit={handleSubmit(onLogin)}>
        <div className="row">
          <div className="col-sm-12 mb-30">
            <div className="header__area-box-sidebar-popup-contact-form-item">
              {" "}
              <i className="far fa-phone"></i>
              <input
                type="text"
                name="phone"
                placeholder="Утасны дугаар"
                {...register("phone", { required: true })}
                className={errors.phone || error ? "border-danger" : ""}
              />
              {errors.phone && (
                <span className="text-danger">Заавал оруулна уу</span>
              )}
            </div>
          </div>
          <div className="col-sm-12 mb-30">
            <div className="header__area-box-sidebar-popup-contact-form-item">
              {" "}
              <i className="far fa-lock"></i>
              <input
                type="password"
                name="password"
                placeholder="Нууц үг"
                {...register("password", { required: true })}
                className={errors.password || error ? "border-danger" : ""}
              />
              {errors.password && (
                <span className="text-danger">Заавал оруулна уу</span>
              )}
              {error && (
                <span className="text-danger">
                  Утасны дугаар нууц үг таарахгүй байна
                </span>
              )}
            </div>
          </div>
          <div className="col-sm-12">
            <div className="header__area-box-sidebar-popup-contact-form-item">
              <button
                className="theme-btn"
                type="submit"
                onClick={() => setError(false)}
              >
                Нэвтрэх
                <i className="fal fa-long-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
