import React, { useState } from "react";

const Features = () => {
  const featuresData = [
    {
      title: "Bachelor",
      description:
        "National University of Mongolia, SFS, International Economic Relations",
    },
    {
      title: "Pre-masters",
      description: "University of Sheffield, Business management and Marketing",
    },
    {
      title: "TV show",
      description: "Finalist of Apprentice Mongolia 2 (Mongol TV, 2022-2023)",
    },
  ];
  const [active, setActive] = useState(1);
  const handelActive = (index) => {
    setActive(index);
  };

  return (
    <div className="features__area-two">
      <div className="container-fluid px-0">
        <div className="row gx-0">
          {featuresData.map((item, index) => (
            <div className="col-xl-4 col-lg-4" key={index}>
              <div
                className={`features__area-two-item ${
                  active === index ? "active" : ""
                }`}
                onMouseEnter={() => handelActive(index)}
              >
                <h4>{item.title}</h4>
                <p>{item.description}</p>
                <div className="features__area-two-item-user">
                  <img src="assets/img/user.png" alt="" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features;
